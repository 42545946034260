#backtotop {
	position: fixed;
	bottom: 99px;
	right :31px;
	height: 42px;
	width: 42px;
	border-radius: 1px;
	transition: opacity .3s ease;
	cursor:pointer;
	background-color: $red;
	// border: 1px solid white;
	border-radius: 50%;
	z-index: 1;
	display: none;
	justify-content: center;
    align-items: center;
	&:hover {opacity:.7;}
	&::after {
		color: $color2;
		font-family: $awesome;
		content: '\f106';
		font-size: 37px;
		transform: translateY(-3px);
	}
@media (max-width: 1023px) {
	bottom: 30px;
	right: 20px;
}
} //#backtotop
