.page_about_top {
  line-height: 2.6em;
  .page_about_top_left {
    background-image: url('../img/about_kadr.jpg');
    background-repeat: no-repeat;
  }
  .page_about_top_right {
  }
  .page_about_top_heading {
    font-size: 21px;
    margin-bottom: 50px;
    strong {font-size: normal;}
  }
  .page_about_top_desc {
    font-weight: normal;
    font-size: 17px;
  }
  .page_about_top_signature_txt {
    font-weight: normal;
  }
@media (max-width: 479px) {
  .page_about_top_left {
    background-size: auto 100%;
    // background-position: left center;
    background-position: center;
    height: 400px;
  }
} //media
@media (min-width: 480px) {
  .page_about_top_left {
    background-size: auto 100%;
    // background-position: left center;
    background-position: center;
    height: 598px;
  }
} //media
@media (max-width: 1023px) {
  .section_wrapper--page_about_top {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
  }
  .page_about_top_left {
    // background-size: auto 100%;
    // background-position: left center;
    // background-position: center;
    // height: 400px;
  }
  .page_about_top_right {
    padding: 0 $padding 30px;
  }
} //media
@media (min-width: 1024px) {
  margin-bottom: -4px;
  z-index: 1;
  position: relative;
  .section_wrapper--page_about_top {
    display: flex;
    justify-content: space-between;
    max-width: 1920px;
    padding-left: 0;
  }
  .page_about_top_left {
    background-size: auto 100%;
    background-position: center;
    // background-position: right -569px center;
    background-position: right center;
    flex-shrink: 0;
    height: 837px;
    flex-basis: 507px;
    align-self: flex-start;
  }
  .page_about_top_desc {
    max-width: 951px;
  }
  .page_about_top_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding: 40px 35px;
    padding-right: 0;
  }
} //media
@media (min-width: 1400px) {
  .page_about_top_left {
    background-size: auto 100%;
    background-position: center;
    background-position: right -588px center;
    background-position: right center;
    flex-shrink: 0;
    height: 872px;
    flex-basis: 697px;
  }
  .page_about_top_right {
    padding: 60px 35px;
  }
} //media
} //.page_about_top
// ##########################################################
.page_about_bottom {
  .page_about_bottom_left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .page_about_bottom_qico {
    align-self: flex-start;
  }
  .page_about_bottom_desc {
    max-width: 905px;
    margin-top: 2em;
    line-height: 2.6em;
    font-size: 17px;
    font-weight: normal;
  }
  .page_about_bottom_signature_txt {
    font-weight: normal;
  }
@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
} //media
@media (max-width: 1023px) {
  .section_wrapper--page_about_bottom {
    border-width: 0 0 4px 0;
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 20px;
  }
  .page_about_bottom_desc {
    margin-bottom: 2em;
  }
  .page_about_bottom_sign {
    width: 210px;
  }
  .page_about_bottom_signature_txt {
    transform: translateY(-50px);
  }
  .page_about_bottom_right_pic {
    display: none;
  }
} //media
@media (min-width: 1024px) {
  display: flex;
  &::before,
  &::after {
    content: '';
    flex-grow: 1;
  }
  &::before {
    @include fastryga;
    border-width: 4px 0 4px 0;
  }
  &::after {
    content: '';
    flex-basis: 50px;
  }
  .section_wrapper--page_about_bottom {
    border-width: 4px 4px 4px 0;
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .page_about_bottom_desc {
    padding-left: 50px;
    margin-bottom: 3em;
  }
  .page_about_bottom_signature_txt {
    flex-grow: 1;
    transform: translateY(-80px);
    font-size: 20px;
  }
  .page_about_bottom_right {
    margin-left: 20px;
  }
  .page_about_bottom_right_pic {
    width: 400px;
    transform: translateX(40px);
    z-index: -1;
    position: relative;
  }
} //media
@media (min-width: 1400px) {
  .page_about_bottom_right_pic {
    width: 600px;

  }
} //media
} //.page_about_bottom
