.home_newsletter {
  text-align: center;
  .heading {
    margin-bottom: 1em;
  }
  .home_newsletter_desc {
  margin-bottom: 3em;
  }
  .home_newsletter_form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .home_newsletter_input {
    flex-grow: 1;
    max-width: 616px;
    border: 1px solid #BCBBBC;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0 10px;
    height: 36px;
  }
  .home_newsletter_btn {
    margin-bottom: 8px;
  }
  .home_newsletter_agree {
    flex-basis: 100%;
  }
@media (max-width: 767px) {
  .home_newsletter_form {
  }
} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  padding: 40px 10px;

} //media
@media (min-width: 1024px) {
  padding: 100px 10px;
  .home_newsletter_agree {
    margin-top: 10px;
  }
} //media
} //.home_newsletter
