.page_default {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
} //media
@media (max-width: 1023px) {
  padding-top: $menu_mobile_h;

} //media
@media (min-width: 1024px) {
  padding-top: $menu_desk_h;
} //media
} //.page_default




.section_default {
  text-align: center;
  flex-grow: 1;
  padding-bottom: 80px;
  .heading {
    margin-bottom: 2em;
  }
@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
} //media
@media (max-width: 1023px) {
} //media
@media (min-width: 1024px) {
} //media
} //.section_default
