#cookieinfo {
  position: fixed;
  z-index: 2;
  font-size: 12px;
  background-color: $red;
  width: 100%;
  box-shadow: 1px 1px 5px 0px rgba(50,50,50,0.75);
  color: $color2;
  &.hidden { display: none; }
  .cookie_wrapper {
  }
  .c_desc {
    font-weight: 300;
  }
  .close {
    position: absolute;
    top: 8px;
    right: 11px;
    font-size: 16px;
    cursor: pointer;
    &::after {
      content: '\f00d';
      font-family: $awesome;
    }
  }
  a {
    text-decoration: underline;
    font-weight: bold;
  }
@media (max-width: 1023px) {
  bottom: 0;
  left: 0;
  padding: 10px;
  padding-right: 25px;
}
@media (min-width: 1024px) {
  bottom: 50px;
  left: 50px;
  padding: 20px;
  width: 500px;
  line-height: 18px;
} //media
} //#cookieinfo
