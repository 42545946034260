.page_shop {
  .section_wrapper--page_shop {
    display: flex;
  }
  .page_shop_list {
    display: flex;
    flex-wrap: wrap;
  }
@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  padding-top: $menu_mobile_h;
  .page_shop_menu {
    display: none !important;
  }
  .page_shop_list {
    justify-content: center;
  }

} //media
@media (min-width: 1024px) {
  padding-top: $menu_desk_h;
  .section_wrapper--page_shop {
    align-items: flex-start;
  }
  .productsList {
    flex-grow: 1;
  }
  .page_shop_menu {
    font-size: 15px;
    font-weight: normal;
    flex-basis: 200px;
    flex-shrink: 0;
    margin-right: 15px;
    // position: sticky;
    // top: 90px; //sticky caused problems
    &>li>a {
      font-weight: 900;
    }
    .selected>a {
      color: $red;
    }
    li>a {
      display: block;
      margin-bottom: 40px;
    }
    a {
      transition: .3s ease;
    }
    a:hover {
      opacity: 1;
      color: $red;
    }
    &>li>a {
      text-transform: uppercase;
    }
    li>ul {
      padding-left: 35px;
    }
  }
  .page_shop_list {
    margin-top: -25px;
  }
  .koss_product_element {
    margin-left: 0;
    margin-right: 0;
    flex-basis: 25%;
    align-items: flex-end;
    padding-left: 20px;
    &>* {max-width: 304px;}
  }
} //media
@media (min-width: 1024px) and (max-width: 1400px) {
  .page_shop_list {
    // justify-content: center;
  }
  .koss_product_element {
    flex-basis: 33%;
  }
} //media
} //.page_shop
