.section_order--step1 {
  text-align: left;
  padding-bottom: 100px;
  .section_wrapper {
    max-width: 1000px;
  }
  .ui.message p {
      display: inline;
  }
  .ui.message {
    margin-bottom: 2em;
  }
  .ui.table {
    margin-bottom: 2em;
  }
  td.del a {
    font-size: 0;
    &::after {
      font-family: $awesome;
      content: '\f00d';
      font-size: 20px;
    }
  }
  .quantity .ui.input {
    a.fa {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    input {
      margin: 0 5px;
    }
  }
  #discountVoucher {
    vertical-align: middle;
    label {
      margin-right: 15px;
    }
    .ui.input {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
  .nextStep {
  text-align: right !important;
  }
} //media
@media (max-width: 1023px) {
} //media
@media (min-width: 1024px) {
} //media
} //.section_order--step1
