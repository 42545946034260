@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x                       : 0px !default;
$hamburger-padding-y                       : 0px !default;
$hamburger-layer-width                     : 28px !default;
$hamburger-layer-height                    : 5px !default;
$hamburger-layer-spacing                   : 5px !default;
$hamburger-layer-color                     : white !default;
$hamburger-layer-border-radius             : 1px !default;
$hamburger-hover-opacity                   : 1 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  // 3dx,
  // 3dx-r,
  // 3dy,
  // 3dy-r,
  // arrow,
  // arrow-r,
  // arrowalt,
  // arrowalt-r,
  // boring,
  // collapse,
  // collapse-r,
  // elastic,
  // elastic-r,
  // emphatic,
  // emphatic-r,
  // slider,
  // slider-r,
  // spring,
  // spring-r,
  // stand,
  // stand-r,
  // spin,
  // spin-r,
  squeeze,
  // vortex,
  // vortex-r
) !default;

// Base Hamburger (We need this)
// ==================================================
@import "hamburgers/base";

// Hamburger types
// ==================================================
// @import "hamburgers/types/3dx";
// @import "hamburgers/types/3dx-r";
// @import "hamburgers/types/3dy";
// @import "hamburgers/types/3dy-r";
// @import "hamburgers/types/arrow";
// @import "hamburgers/types/arrow-r";
// @import "hamburgers/types/arrowalt";
// @import "hamburgers/types/arrowalt-r";
// @import "hamburgers/types/boring";
// @import "hamburgers/types/collapse";
// @import "hamburgers/types/collapse-r";
// @import "hamburgers/types/elastic";
// @import "hamburgers/types/elastic-r";
// @import "hamburgers/types/emphatic";
// @import "hamburgers/types/emphatic-r";
// @import "hamburgers/types/slider";
// @import "hamburgers/types/slider-r";
// @import "hamburgers/types/spring";
// @import "hamburgers/types/spring-r";
// @import "hamburgers/types/stand";
// @import "hamburgers/types/stand-r";
// @import "hamburgers/types/spin";
// @import "hamburgers/types/spin-r";
@import "hamburgers/types/squeeze";
// @import "hamburgers/types/vortex";
// @import "hamburgers/types/vortex-r";
