.section_order--step2 {
  text-align: left;
  padding-bottom: 100px;
  // .ui.message {
  //   margin-bottom: 2em;
  // }
  // .ui.table {
  //   margin-bottom: 2em;
  // }
  .section_wrapper {
    max-width: 1000px;
  }
  #koss_btn {
    margin-bottom: 1em;
  }
  .ui.form {
    // max-width: 880px;
    // margin: 0 auto;
    .warning-required {
      background: #fff6f6;
      border-color: #e0b4b4;
      color: #9f3a38;
    }
  }
  #paymentChannels select {
    display: none; //default value
  }
  #orderRegisterForm,
  #orderLoginForm {
    margin-bottom: 3em;
  }
  #personalDataBlock,
  #shippingAndPayments {
    margin-bottom: 2em;
  }

  #paymentChannels {
    display: none; //default value
  }
@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
  .nextStep {
  text-align: right !important;
  }
} //media
@media (max-width: 1023px) {
} //media
@media (min-width: 1024px) {
} //media
} //.section_order--step2
