.section_order--step3 {
  text-align: left;
  padding-bottom: 100px;

@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
} //media
@media (max-width: 1023px) {
} //media
@media (min-width: 1024px) {
} //media
} //.section_order--step3
