.section_instagram {

  #instafeed {
    // height: 483px;
    // img {height: 100%;}
  }
@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  height: 180px;
  #instafeed {
    height: 180px;
     img {height: 180px;}
  }
} //media
@media (min-width: 1024px) {
  // height: 483px;
  #instafeed {
    .insta_el {
      height: 450px;
       img {height: 450px;}
    }
    // height: 483px;
  }
} //media
} //.section_instagram
