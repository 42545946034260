.page_shop--product_details {
  .product_right_details_image {
    img {width: 100%;}
  }
  .product_right_details_sticky_content {
    font-size: 15px;
  }
  .product_details_buybox {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    max-width: 390px;
  }
  .product_details_price_old {
    margin-top: -10px;
    margin-bottom: 30px;
    opacity: .7;
    text-decoration: line-through;
  }
  .color_options {
    display: flex;
    justify-content: center;
  }
  .color_options_element {
    width: 31px;
    height: 31px;
    display: block;
    margin: 0 6px;
    border: 5px solid white;
    border-radius: 50%;
    padding: 4px;
    &:hover {
      opacity: 1;
      box-shadow: 0 0 1px 1px black;
    }
  }
  .ui.selection.dropdown {
    width: 100%;
    &, .menu {border-radius: 0 !important;}
  }
  #attributesSelects {
    font-size: 20px;
    margin-bottom: 14px;
  }
  .product_details_buy_btn {
    width: 100%;
    border: 2px solid black;
    background-color: transparent;
    font-weight: normal;
    text-transform: uppercase;
    height: 53px;
    margin-bottom: 14px;
    cursor: pointer;
  }
  .product_details_buy_wishlist {
    width: 100%;
    border: 1px solid black;
    background-color: transparent;
    font-weight: normal;
    text-transform: uppercase;
    height: 53px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .fa {
      transition: .2s ease;
      margin-right: 10px;
    }
    &.selected .fa {
      color: $red;
      transform: scale(2.2);
      margin-right: 10px;
    }
  }
  .product_details_buy_icons {
    justify-content: space-around;
    margin-bottom: 50px;
  }
  .product_details_buy_icons_el {
    flex-basis: calc(33% - 10px);
  }
  .product_details_descriptionFull {
    line-height: 1.9em;
    font-weight: normal;
    width: 100%;
    max-width: 420px;
    margin-bottom: 50px;
    strong {
      color: #737373;
      text-transform: uppercase;
    }
  }
@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  padding-top: $menu_mobile_h;
  .product_right {
    width: 100%;
  }
  .product_right_details_image {
    .imagesList {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
    }
  }  .product_details_title {
    font-size: 26px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .product_details_buybox {
    margin-left: auto;
    margin-right: auto;
  }
  .product_details_descriptionFull {
    margin-left: auto;
    margin-right: auto;
  }
  .product_details_price {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .product_details_price_old {
    font-size: 20px;
  }
} //media
@media (min-width: 1024px) {
  padding-top: $menu_desk_h;
  .product_right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .product_right_details {
    display: flex;
  }
  .product_right_details_image {
    // flex-basis: 600px;
    .imagesList {width: 400px;}
  }
  .product_right_details_sticky {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .product_right_details_sticky_content {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    align-items: center;

    position: sticky;
    top: 50px;
  }
  .product_details_title {
    font-size: 34px;
    font-weight: 300;
    margin-top: 60px;
    margin-bottom: 30px;
    text-align: center;
  }
  .product_details_price {
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .product_details_price_old {
    font-size: 28px;
  }
  .color_options {
    margin-bottom: 30px;
  }
  .color_options_element {
  }
} //media
@media (min-width: 1400px) {
  .product_right_details_image {
    .imagesList {width: 600px;}
  }
  .product_right_details_sticky_content {
    padding-left: 120px;
  }
} //media
} //.page_shop--product_details
