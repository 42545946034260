.page_register {

.section_wrapper--page_login {
  max-width: 920px;
}
.heading--page_register {
  margin-bottom: 50px;
}
.ui.form {
  margin: 0 auto;
  .field {
    text-align: left;
  }
  .warning-required {
    background: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
  }
}
#notRequired {display: none;}
label[for=captcha] {
  margin-right: 10px !important;
  display: inline-block !important;
  vertical-align: middle;
}
#captcha {
  width: 60px;
  vertical-align: middle;
}
.koss_btn {
  margin: 10px 0;
}

[name="sPhone"] {
  margin-bottom: 1em !important;
}

.ui.message {
  text-align: left;
}

@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  padding-top: $menu_mobile_h;

} //media
@media (min-width: 1024px) {
  padding-top: $menu_desk_h;
} //media
} //.page_register
