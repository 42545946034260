.page_media {
  flex-grow: 1;
  .section_wrapper--page_media {
  }
  .page_media_menu_container {
    flex-basis: 200px;
    flex-shrink: 0;
    .element {
      margin-bottom: 40px;
    }
  }
  .page_media_menu {
    font-size: 15px;
    font-weight: normal;
    .element.selected {
      font-weight: bold;
      color: $red;
    }
    .element a {
      transition: .3s ease;
    }
    .element a:hover {
      opacity: 1;
      color: $red;
    }
    .element {
      padding-left: 35px;
    }
  }
  .page_media_menu_title {
    text-transform: uppercase;
    color: $red;
    font-weight: bold;
    display: block;
  }
  .page_media_content {
    flex-basis: 1370px;
    font-size: 15px;
    text-align: center;
  }
  .page_media_content_video {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 45px;
    img {width: 100%;}
  }
  .page_media_list {
    .element {
      margin-bottom: 45px;
      font-size: 20px;
      padding-bottom: 40px;
      @include fastryga;
      border-width: 0 0 4px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    .element:last-of-type {
      border-width: 0;
    }
    .photo_container {
      margin-bottom: 20px;
      position: relative;
      flex-basis: 100%;
      a:hover {opacity: 1;}
    }
    .photo_container::before {
      content: '';
      @include absolute;
      background-color: rgba(0,0,0,.5);
      pointer-events: none;
    }
    .photo_btn {
      @include center_absoluteXY;
      background-color: $red;
      color: white;
      font-size: 20px;
      padding: 10px 30px;
      text-transform: uppercase;
      pointer-events: none;
      white-space: nowrap;
    }
    .description {
      margin: 25px;
    }
    img {width: 100%;}
    font-weight: normal;
    strong {font-weight: bold;}
    .koss_btn {
      font-weight: bold;
      margin: 25px;
    }
  }

@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  .page_media_menu_container {
    display: none;
  }
  .page_media_content_subgallery_list {
    .element {
      // height: 400px;
      // max-width: 1000px;
    }
  }
} //media
@media (min-width: 1024px) {
  .section_wrapper--page_media {
    display: flex;
    justify-content: space-between;
  }
  .page_media_content_subgallery_list {
    .element {
      height: 300px;
      max-width: 1000px;
    }
  }
} //media

@media (min-width: 1600px) {

  .page_media_content_subgallery_list {
    .element {
      height: 684px;
    }
  }
} //media

} //.page_media
