.heading {
	font-size: 20px;
	font-weight: normal;
}

.fastryga { @include fastryga; }

#koss_btn,
.koss_btn {
	font-size: 20px;
	font-weight: normal;
	border: 3px solid black;
	padding: 5px 30px;
	text-transform: uppercase;
	display: inline-block;
	text-align: center;
	background-color: transparent;
	cursor: pointer;
	transition: .25s ease;
	&:hover {
		opacity: 1!important;
		font-size: 20px;
		font-weight: normal;
		border-color: $red;
		background-color: $red;
		color: white;
	}

}

@keyframes highlightedAnim {
	0% {
		opacity:1;
		transform: scale(0);
	}
	100% {
		opacity:0;
		transform: scale(2);
	}
}

.koss_product_element {
	display: flex;
	flex-direction: column;
	font-size: 15px;
	text-align: left;
	.basket {display: none;}
	.koss_product_img {
		position: relative;
		a:hover {
			opacity: 1;
			cursor: default;
		}
	}
	.photo {
		transition: opacity .3s ease;
	}
	.koss_product_element_highlighted {
		position: absolute;
		right: 15px;
		bottom: 17px;
		z-index: 1;
	}
	.koss_product_element_highlighted::after {
		content: '\f004';
		font-size: 22px;
		font-family: $awesome;
		cursor: pointer;
		position: absolute;
		right: 0;
		bottom: 0;
		transition: .2s ease;
	}

	.koss_product_element_highlighted::before {
		content: '\f004';
		font-size: 22px;
		font-family: $awesome;
		position: absolute;
		color: white;
		right: 0;
		bottom: 0;
		transition: .2s ease;
		opacity: 0;
	}
	.koss_product_element_highlighted.selected::before {
		animation: highlightedAnim linear .3s;
		animation-iteration-count: 1;
		transform-origin: 50% 50%;
		z-index: 1;
	}
	.koss_product_element_highlighted.selected::after {
		color: $red;
		transform: scale(1.5);
	}
	.koss_product_element_highlighted:hover::after {
		color: $red;
	}
	img {width: 100%;}
	img {
		cursor: pointer;
	}
	.title {
		margin: 10px 0;
		font-weight: normal;
	}
	.price {
		font-weight: bold;
		margin-bottom: 8px;
		font-size: 17px;
		.old {
			opacity: 0.5;
			font-size: 0.8em;
			margin-left: 8px;
			text-decoration: line-through;
		}
	}
	.sizes {
		display: flex;
		transition: all .15s ease;
		span {margin-right: 5px;}
		.sold {
			text-decoration: line-through;
			color: $red;
		}
	}
	.koss_product_img_hover {
		@include absolute;
		pointer-events: none;
		display: flex;
		justify-content: center;
		align-items: center;
		// z-index: -1; //not to cover wishlist heart
		.imagesList {
			opacity: 0;
			transition: opacity .27s ease;
			background-color: white;
		}
		li:not(:first-of-type) {display: none;} //to secure not to put more than one image for hover
	}
@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
} //media
@media (max-width: 1023px) {
	width: 280px;
	margin: 15px 20px;
} //media
@media (min-width: 1024px) {
	width: 304px;
	margin: 25px 38px;
} //media
}
// hover of prices, but only when is not touchscreen
body.noTouchscreen .koss_product_element:not(:hover) .sizes {
	opacity: 0;
	transform: translateY(-20px);
}
body.noTouchscreen .koss_product_element:hover {
	.koss_product_img_hover .imagesList {
		opacity: 1;
	}
	.photo {
		// opacity: 0;
	}
}

.koss_breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	text-transform: uppercase;
	color: #bababa;
	font-size: 0;
	a, span {font-size: 16px;}
	a:not(:last-child)::after {
		font-size: 16px;
		content: '/';
		margin: 0 5px;
	}
@media (max-width: 1023px) {
	padding-top: 25px;
	padding-bottom: 25px;
} //media
@media (min-width: 1024px) {
	padding-top: 48px;
	padding-bottom: 48px;
} //media
}


.breadcrumbs {
	background-color: $color1;
	font-size: 14px;
	color: #828389;
	text-transform: uppercase;
	padding: 16px 0;
}
.breadcrumbs_element:not(:last-child) {
	&::after {
		content: '/';
		margin: 0 1em;
	}
}
.breadcrumbs_element:last-child {
	color: $color2;
	font-weight: 900;
}


.order_products_name {
	font-weight: 700;
}

.filesListMp3 {
	padding-top: 50px;
}
.filesListMp3Element:not(:last-of-type) {
	margin-bottom: 30px;
}
.filesListMp3ElementDesc {
	margin-bottom: 15px;
	font-weight: bold;
}

.product_details_buy_icons {
	font-size: 10px;
	color: $red;
	text-transform: uppercase;
	display: flex;
	justify-content: space-around;
}
.product_details_buy_icons_el {
	// flex-basis: calc(33% - 10px);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	span {margin-top: 10px;}
	&:hover {opacity: 1;}
	&:not(:hover) {filter: grayscale(100%) brightness(51%);}
}
.section_icons_bar {
	background-color: #edf1f0;
	padding: 10px 0;
	.product_details_buy_icons {
		max-width: 350px;
		margin: 0 auto;
	}
	.product_details_buy_icons_el {
		flex-basis: calc(33% - 10px);
	}
}

.page_home_h1 {
	position: absolute;
	pointer-events: none;
}
