#section_footer {
	color: $color2;
	background-color: #151515;
	// strong {color: rgb(60,60,60);}
	text-align: center;
	// font-size: 14px;
	.section_wrapper{
		// display: flex;
		// justify-content: center;
	}
	strong {font-weight: bold;}
	transition: margin-bottom .16s linear;
	&.cookable {margin-bottom: 48px;}

@media (max-width: 480px) {
	&.cookable {margin-bottom: 84px;}
} //media
@media (max-width: 1023px) {
	padding: 30px 0;
} //media
@media (min-width: 1024px) {
	padding: 55px 0;
	.after::after {
		content: '|';
		margin: 0 10px;
	}
	&.cookable {margin-bottom: 0 !important;}
} //media
} //#section_footer
