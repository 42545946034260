.home_seo {
  text-align: center;
  .section_wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .heading {
    margin-bottom: 1em;
  }
  // .home_seo_desc {
  // margin-bottom: 3em;
  // }
@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
} //media
@media (max-width: 1023px) {
  padding-bottom: 40px;
} //media
@media (min-width: 1024px) {
  padding-bottom: 100px;
} //media
} //.home_seo
