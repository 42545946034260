// ################ VARIABLES ONLY ###########
$font_default: 'Lato', sans-serif;
$font_weight: 300;
$awesome: 'FontAwesome';

$wrapper:1720px; //without padding
$padding: 20px;

$color1: #45464a;
$color2: white;

$color_menu: #0a0a0a;
$color_menu2: #d20641;

$red: #d20641;
// $black: rgb(17, 17, 13);
//
$background: #f1f3f5;
$menu_desk_h: 127px;
$menu_mobile_h: 57px;
//
// $blue: rgb(19, 20, 97);
// $blue2: rgb(55, 109, 233);
//
// $green: rgb(55,154,57);
