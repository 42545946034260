.page_shows {
  flex-grow: 1;
  border-width: 0 0 4px 0;
  padding-bottom: 70px;
  .section_wrapper--page_shows {
  }
  .page_shows_menu_container {
    flex-basis: 200px;
    flex-shrink: 0;
    .element {
      margin-bottom: 40px;
    }
  }
  .page_shows_menu {
    font-size: 15px;
    font-weight: normal;
    .element.selected {
      font-weight: bold;
      color: $red;
    }
    .element a {
      transition: .3s ease;
    }
    .element a:hover {
      opacity: 1;
      color: $red;
    }
    .element {
      padding-left: 35px;
    }
  }
  .page_shows_menu_title {
    text-transform: uppercase;
    color: $red;
    font-weight: bold;
    display: block;
  }
  .page_shows_content {
    flex-basis: 1370px;
    font-size: 15px;
    text-align: center;
  }
  .page_shows_content_video {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 45px;
    img {width: 100%;}
  }
  .page_shows_content_video::before {
    content: '';
    opacity: 0;
    transition: .3s ease;
    @include absolute;
    background-color: rgba(0,0,0,.5);
    pointer-events: none;
  }
  .page_shows_content_video:hover,
  .page_shows_content_video:hover::before,
  .page_shows_content_video:hover .page_shows_content_video_btn {opacity: 1;}

  .page_shows_content_video_btn {
    @include center_absoluteXY;
    background-color: white;
    font-size: 20px;
    padding: 10px 30px;
    text-transform: uppercase;
    pointer-events: none;
  }
  .page_shows_content_desc {
    max-width: 890px;
    margin: 0 auto;
    line-height: 2.6em;
  }
  .page_shows_content_desc_short {
    margin-bottom: 45px;
    font-size: 20px;
  }
  .page_shows_content_subgallery_list {
    width: 100%;
    max-width: 1370px;
    // height: 684px;
    img {height: 100%;}
    .element {
      // height: 684px;
    }
  }
  .page_shows_content_subgallery_title {
    font-weight: normal;
    font-size: 20px;
    margin: 60px 0;
  }
@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  .page_shows_menu_container {
    display: none;
  }
  .page_shows_content_subgallery_list {
    .element {
      height: 420px;
    }
  }
} //media
@media (min-width: 1024px) {
  .section_wrapper--page_shows {
    display: flex;
    justify-content: space-between;
  }
  .page_shows_content_subgallery_list {
    width: calc(100vw - 240px);
    .element {
      height: 300px;
      max-width: 1000px;
    }
  }
} //media

@media (min-width: 1600px) {

  .page_shows_content_subgallery_list {
    .element {
      height: 684px;
    }
  }
} //media

} //.page_shows
