/* IE10+ hack styles */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	//some code for this hack
}
body.safari {
  //some code for this hack
}

body:not(.noTouchscreen) {
  //for touch devices
  .home_shows .home_shows_image_text { opacity: 1; }

}

.ui.message i.fa {margin-right: 8px;}

@media only screen and (max-width: 767px) {
  .ui.selection.dropdown .menu {
    max-height: none;
  }
}
