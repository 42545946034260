.section_follow {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  &>* {margin: 10px;}
  .section_follow_icons {
    display: flex;
    flex-wrap: wrap;
  }
  .section_follow_icons_el {
    transition: filter .3s ease;
  }
  .section_follow_icons_el:hover {
    opacity: 1;
  }
  .section_follow_icons_el:not(:hover) {
    filter: grayscale(100%) brightness(51%);
  }


@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  padding: 30px 0;
  .section_follow_icons_el:not(:last-of-type) {
    margin-right: 20px;
  }
} //media
@media (min-width: 1024px) {
  padding: 55px 0;
  .section_follow_icons_el:not(:last-of-type) {
    margin-right: 35px;
  }
} //media
} //.section_follow
