.home_shop {
  text-align: center;
  .section_wrapper {
    max-width: calc(1140px + 40px);
  }
  .heading {
    text-transform: uppercase;
    margin-bottom: 1em;
  }
@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  padding: 40px 15px;
} //media
@media (min-width: 1024px) {
  padding: 90px 20px;

} //media
} //.home_shop
