.home_about {
  margin-top: 10px;
  display: flex;
  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    @include fastryga;
  }
  &::before {
    border-width: 0 0 4px 0;
  }
  &::after {
    border-width: 4px 0 0 0;
  }
  .section_wrapper {
    border-width: 0 4px 4px 0;
  }
  .home_about_left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .home_about_qico {
    align-self: flex-start;
  }
  .home_about_desc {
    max-width: 905px;
    margin-top: 2em;
    line-height: 2.6em;
  }
  .home_about_signature_txt {
    font-weight: normal;
  }
@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  &::after {flex-basis: 20px;}
  .home_about_desc {
    padding-left: 15px;
  }
  .home_about_sign {
    width: 210px;
  }
  .home_about_signature_txt {
    transform: translateY(-50px);
  }
  .koss_btn {
    margin-bottom: 50px;
  }
  .home_about_right_pic {
    display: none;
  }
} //media
@media (min-width: 1024px) {
  &::after {flex-basis: 50px;}
  .section_wrapper {
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .home_about_desc {
    padding-left: 50px;
    font-size: 20px;
    text-align: justify;
  }
  .home_about_signature_txt {
    flex-grow: 1;
    transform: translateY(-80px);
    font-size: 20px;
  }
  .home_about_right {
    margin-left: 20px;
  }
  .home_about_right_pic {
    width: 400px;
    transform: translateX(50px);
    z-index: -1;
    position: relative;
  }
} //media
@media (min-width: 1400px) {
  .home_about_right_pic {
    width: 600px;

  }
} //media
} //.home_about
