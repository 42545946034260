.home_slider {
  height: 100vh;
  position: relative;
  color: white;
  .slick-track, .slick-list {height: 100%;}
  .home_slider_list {
    display: flex;
    transition: all .3s ease;
    height: 100%;
  }
  .home_slider_list .element {
    background-size: cover;
    background-position: center;
    background-position: center top;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .home_slider_list .element a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .home_slider_list .description {
    padding: 10px;
    p:not(:last-of-type) {margin-bottom: 1em;}
  }

@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  padding-top: $menu_mobile_h;
  .home_slider_list .description {
    font-size: 28px;
  }
} //media
@media (min-width: 1024px) {
  padding-top: $menu_desk_h;
  .home_slider_list .description {
    font-size: 42px;
  }
} //media
} //#home_slider
