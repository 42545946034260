// ################ GLOBAL SETTINGS ###########
* { box-sizing:border-box; }
body{
  font-family: $font_default;
  color: $color1;
  font-weight: $font_weight;
  // background-color: $background;
  background-color: white;
}

*:focus {
  // outline: 2px solid $red;
  outline-color: $red;
}

// ################ DEFAULT STRUCTURE SETTINGS ###########
#main_container {
  display: flex;
  flex-direction: column;
}
.page_section {
	width:100%;
  overflow: hidden;
}
.section_wrapper {
	width: 100%;
	max-width: $wrapper + $padding + $padding; //double padding added because of box-sizing: border-box
	margin: 0 auto;
	padding-left: $padding;
	padding-right: $padding;
}
// .page_home .section_wrapper {
// 	max-width: $wrapper_home;
//   padding: 0;
// }

// ################ OTHER SETTINGS ###########
// p, ul, ol { line-height: 22px; }
strong { font-weight: normal; }

@include selection($color2, $red); //font, bgn

//hover options not for touch devices - detecting by js: touchscreenDetect()
body.noTouchscreen {
	button, a {
		transition: color .3s ease,
		background-color .3s ease,
		border-color .3s ease,
		opacity .3s ease;
	}
	button:hover, a:hover { opacity: 0.7; }
  a[href^="tel:"] {pointer-events: none;} //disables tel: protocol on most desktop devices
}

//pnotify
.brighttheme-info {
  background-color: $red;
  border: 0 solid $red;
  color: $color2;
}

//settings for slider not to enlarge page
.beforeLoad>*:not(:first-of-type) {display: none !important;}

body>.ui-pnotify {
  z-index: 2147483645!important; //because of trusted icon  
}
