.page_login {

.heading--page_login {
  margin-bottom: 50px;
}
.ui.form {
  max-width: 330px;
  margin: 0 auto;
  .field {
    text-align: left;
  }
}
.koss_btn {
  margin: 10px 0;
}
.koss_btn--forgot {
  // opacity: .5;
  // transform: scale(.7);
}

@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  padding-top: $menu_mobile_h;

} //media
@media (min-width: 1024px) {
  padding-top: $menu_desk_h;
} //media
} //.page_login
