#section_contact {
  overflow: hidden;
  .section_wrapper.section_wrapper--contactform {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  .contact_form {
    flex-basis: 780px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .section_contact_heading {
    flex-basis: 100%;
    margin-bottom: 23px;
    text-align: center;
  }
  .section_contact_desc {
    flex-basis: 100%;
    font-size: 15px;
    margin-bottom: 40px;
    text-align: center;
    line-height: 1.9em;
  }
  input, textarea {
    background-color: transparent;
    border: 1px solid #B5B4B5;
    color: inherit;
    font-size: 17px;
    padding: 14px 18px;
    margin-bottom: 16px;
    width: 100%;
  }
  input[type="text"],
  input[type="email"] {
    flex-basis: calc(50% - 8px);
  }
  // .contact_form_inputs_container {
  //   display: flex;
  //   align-self: stretch;
  // }
  .send_btn {
    margin-left: auto;
  }
  .section_contact_box {
    margin: 50px 40px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .section_contact_box_el {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    color: $red;
    font-weight: normal;
    img {margin-right: 18px;}
    &:not(:hover) {filter: grayscale(100%) brightness(51%);}
  }
  .section_wrapper.section_wrapper--mapLocations {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #google_map {
    flex-basis: 1050px;
    flex-grow: 1;
  }
  .section_contact_locations {
    line-height: 2.5em;
    font-weight: normal;
    font-size: 15px;
    strong {font-weight: bold;}
  }
  .section_contact_locations_title {
    font-weight: bold;
    color: $red;
  }
  .section_contact_locations_txt {

  }

@media (max-width: 640px) {
  input[type="text"],
  input[type="email"] {
    flex-basis: 100%;
  }
} //media
@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
} //media
@media (max-width: 1023px) {
  textarea {
    height: 150px;
  }
	#google_map { height: 400px; }
} //media
@media (min-width: 1024px) {
  textarea {
    height: 255px;
  }
	#google_map { height: 530px; }
} //media
@media (max-width: 1400px) {
  .section_wrapper.section_wrapper--mapLocations {
    flex-wrap: wrap;
    padding: 0;
  }
  .section_contact_locations {
    padding: 60px 15px 0;
  }
} //media
@media (min-width: 1401px) {
  overflow: hidden;
  .section_wrapper.section_wrapper--mapLocations {
    padding-bottom: 60px;
  }
  .section_contact_locations {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 630px;
    padding: 20px 50px;
    &::after {
      @include absolute;
      @include fastryga;
      left: -30px;
      z-index: -1;
      content: '';
      width: 200%;
      border-right: 0;
    }
  }
} //media
} //#section_contact
