.home_shows {
  display: flex;
  text-align: center;
  max-width: 1920px;
  margin: 0 auto;
  .heading {
    text-transform: uppercase;
    margin-top: 2em;
    margin-bottom: 1em;
  }
  .home_shows_image {
    position: relative;
    img {width: 100%;}
  }
  .home_shows_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home_shows_content_sub {
    font-size: 17px;
    margin-bottom: 4em;
  }
@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  .home_shows_content {
    padding: 60px 0;
    width: 100%;
  }
  .home_shows_image {
    display: none;
  }
} //media
@media (min-width: 1024px) {
  margin-top: -4px; //cover border bottom of above section
  .home_shows_image {
    flex-basis: 50%;
  }
  .home_shows_image_text {
    @include absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(0,0,0,.5);
    opacity: 0;
    transition: all .3s ease;
  }
  .home_shows_image_link:hover {
    opacity: 1; //to prevent default opacity on link
    .home_shows_image_text {
      opacity: 1;
    }
  }
  .home_shows_content {
    flex-basis: 50%;
  }
} //media
@media (min-width: 1400px) {
  .home_shows_image_text {
    font-size: 20px;
    line-height: 2.6em;
  }
} //media
} //.home_shows
