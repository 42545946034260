#navigation {

	left: 0;
	top: 0;
	right: 0;
	z-index: 3;
	position: fixed;
	nav.nav_mobile {display: none;} //hide by default


@media (max-width: 1023px) {
	.nav_menu_desk {display: none;}
	nav.nav_top {
		width: 100%;
		background-color: white;
		border-bottom: 1px solid #CCCCCC;
	}
	.nav_top_wrapper {
		display: flex;
	}
	.nav_top_logo_a {
		display: flex;
	}
	.nav_top_logo {
		width: 176px;
		margin: 14px 0;
	}

	button.hamburger {
		position: absolute;
		right: 20px;
		top: 15px;
		z-index: 1;
		outline: none;
		.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
			background-color: $red;
		}
	}
	button.hamburger:not(.is-active) {
		.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
			// box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.66);
		}
	}
	button.hamburger.is-active {
	}
	nav.nav_mobile {
		top: 0;
		left: 0;
		right: 0;
		height: 100vh;
		opacity: 1;
		overflow: hidden;
		position: absolute;
		background-color: $color1;
		color: $color2;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: opacity .2s linear;
	}
	.nav_menu_mobile {
		position: absolute;
		top: 0; bottom: 0; left: 0; right:0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		// text-transform: uppercase;
		font-size: 30px;
	}
	.nav_menu_mobile>li {transition: transform .2s ease; transition-delay: .3s;}
	.nav_menu_mobile>li:not(:last-of-type) {
		margin-bottom: 25px;
	}
	nav.nav_mobile:not(.show) {
		opacity: 0;
		pointer-events: none;
		.nav_menu_mobile>li {visibility: hidden;}
		.nav_menu_mobile>li:nth-child(odd) {transform: translateX(-100vw);}
		.nav_menu_mobile>li:nth-child(even) {transform: translateX(100vw);}
	}
	.nav_menu_mobile_el.lang {
		display: flex;
	}
	.nav_menu_mobile_lang_el {
	}
	.nav_menu_mobile_lang_el:not(:last-of-type) {
		margin-right: 20px;
	}
	.nav_menu_mobile_lang_el>img {
		max-height: 22px;
		margin-right: 10px;
	}
} //media
@media (min-width: 1024px) {
	.hamburger {display: none;}
	nav.nav_mobile {display: none !important;}

	nav.nav_top {
		width: 100%;
		text-transform: uppercase;
		font-weight: normal;
		font-size: 15px;
		color: $color_menu;
		background-color: white;
		border-bottom: 1px solid #CCCCCC;
		a:hover {
			opacity: 1;
			color: $red;
		}
	}
	.nav_top_wrapper {
		transition: all .2s linear;
		height: $menu_desk_h;
		// padding: 0;
		display: flex;
		align-items: center;
		position: relative;
	}
	.nav_top_logo {
		// height: 42px;
		width: 176px;
		transition: all .3s ease;
		margin-right: 39px;
	}
	.nav_menu_desk {
		display: flex;
		&:nth-of-type(2) {
			flex-grow: 1;
			justify-content: flex-end;
		}
	}
	.nav_menu_desk>li {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.nav_menu_desk>li:not(:last-of-type) {
		margin-right: 36px;
	}
	.nav_menu_desk>li>a {
		position: relative;
	}
	.nav_menu_desk>li>a::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -8px;
		background-color: transparent;
		height: 4px;
		transition: all .2s ease;
	}
	.nav_menu_desk>li>a:hover::after {
		background-color: $red;
	}

	.nav_menu_desk .nav_icon_fav,
	.nav_menu_desk .lBasket {
		font-size: 0 !important;
		a::before {
			font-family: $awesome;
			margin-right: 3px;
		}
		strong {
			font-size: 15px;
			&::before {content: '(';}
			&::after {content: ')';}
		}
	}
	.nav_menu_desk .nav_icon_fav a::before {
		content: '\f004';
		font-size: 17px;
	}
	.nav_menu_desk .lBasket a::before {
		content: '\f07a';
		font-size: 20px;
	}
	.nav_menu_desk--fav {
		margin-left: 12px;
	}
	.nav_menu_desk--lang {
		margin-left: 36px;
	}
	.nav_menu_desk--lang>li {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.nav_menu_desk--lang>li:not(:last-of-type) {
		margin-right: 10px;
		&::after {
			content: '/';
			margin-left: 10px;
		}
	}


	//after scrolling down desktop menu settings
	&.scrolled {
		nav.nav_top {
			box-shadow: 0 0 5px rgba(0,0,0,.5);
		}
		.nav_top_wrapper {
			height: 60px;
		}
		.nav_menu_desk {
			color: $color1;
		}
	}
	//after scrolling down desktop menu settings ## END

} //media
@media (min-width: 1024px) and (max-width: 1400px) {
	.nav_top_logo {
		margin-right: 25px;
		width: 140px;
	}
	nav.nav_top {
		font-size: 14px;
	}
	.nav_menu_desk>li:not(:last-of-type) {
		margin-right: 15px;
		white-space: nowrap;
	}
	.nav_menu_desk--fav {
		margin-left: 7px;
	}
	.nav_menu_desk--lang  {
		margin-left: 15px;
	}
	.nav_menu_desk--lang>li:not(:last-of-type) {
		margin-right: 6px;
		&::after {margin-left: 6px;}
	}
} //media
} //#navigation


// ################ JQUERY MMENU STYLING ###########
.jsMmenuInit.mm-menu {
	z-index: 4;
	font-weight: normal;
	.lBasket {
		display: flex;
		span {
			font-size: 0;
			strong {
				font-size: 16px;
				&::before {content: '(';}
				&::after {content: ')';}
			}
		}
	}
}
