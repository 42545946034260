.home_lookbook {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  .home_lookbook_a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .heading {
    color: white;
    line-height: 2.6em;
    font-weight: inherit;
    strong {
      font-weight: normal;
    }
  }
@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  .heading {
    font-size: 28px;
  }
} //media
@media (min-width: 1024px) {
  .heading {
    font-size: 42px;
  }
} //media
} //.home_lookbook
