
@mixin placeholder {
  &::-webkit-input-placeholder { @content }
  &::-moz-placeholder { @content }
  &:-ms-input-placeholder { @content }
  &:-moz-placeholder { @content }
  &:placeholder-shown { @content }
  &::placeholder { @content }
}


@mixin selection($czcionka, $tlo){
  /* WebKit/Blink Browsers */
  ::selection {
    background: $tlo;
    color:$czcionka;
  }
  /* Gecko Browsers */
  ::-moz-selection {
    background: $tlo;
    color:$czcionka;
  }
  // .section_inverted ::selection {
  //   background: $czcionka;
  //   color:$tlo;
  // }
  // /* Gecko Browsers */
  // .section_inverted ::-moz-selection {
  //   background: $czcionka,;
  //   color:$tlo;
  // }
}

@mixin absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


@mixin center_absoluteXY {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

@mixin fastryga {
  border-width: 4px;
  border-style: solid;
  border-image-source: url(../img/border.png);
  border-image-slice: 4;
  border-image-repeat: round;
}
