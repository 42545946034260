.page_lookbook {
  text-align: center;
  padding: 50px 0;
  .heading {
    text-transform: uppercase;
    margin-bottom: 1em;
  }
  .heading_sub {
    margin-bottom: 2em;
  }
  .page_lookbook_desc {
    max-width: 980px;
    margin: 0 auto;
    margin-bottom: 4em;
    font-size: 15px;
    line-height: 2.5em;
  }
  .page_lookbook_btn_shop {
    margin-bottom: 3em;
  }
  .slick-dots li::after {
    background-color: #979797;
  }
@media (max-width: 767px) {

} //media
@media (min-width: 768px) {

} //media
@media (max-width: 1023px) {
  .page_lookbook_slider_list {
    img {
      height: 500px;
    }
  }
} //media
@media (min-width: 1024px) {
  .heading_sub {
    font-size: 20px;
  }
  .page_lookbook_slider_list {
    img {
      height: 860px;
    }
  }
} //media
} //.page_lookbook
