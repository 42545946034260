.page_favorites {
  flex-grow: 1;
  .section_wrapper--favorites_desc {
    text-align: center;
  }
  .heading {
    text-transform: uppercase;
    margin-bottom: 1em;
  }
  .page_lookbook_desc {
    max-width: 980px;
    margin: 0 auto;
    font-size: 15px;
    line-height: 2.5em;
  }
  .page_favorites_list {
    display: flex;
    flex-wrap: wrap;
  }
@media (max-width: 767px) {
} //media
@media (min-width: 768px) {
} //media
@media (max-width: 1023px) {
  .page_favorites_list {
    justify-content: center;
  }
} //media
@media (min-width: 1024px) {
  .koss_product_element {
    margin-left: 0;
    margin-right: 0;
    flex-basis: 25%;
    align-items: flex-end;
    padding-left: 20px;
    &>* {max-width: 304px;}
  }
} //media
@media (min-width: 1024px) and (max-width: 1400px) {
  .koss_product_element {
    flex-basis: 33%;
  }
} //media
} //.page_favorites
